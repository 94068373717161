import React, { useState } from 'react';
import Filters from './symptoms/Filters';
import List from './symptoms/List';

const Symptoms = () => {
  const [stage, setStage] = useState('ALL');
  return (
    <section className='symptoms-wrapper'>
      <Filters setStage={setStage} stage={stage} />
      <List stage={stage} />
    </section>
  );
};

export default Symptoms;