import React from 'react';
import { useCookies } from 'react-cookie';
import filter from 'lodash/filter';
import { QUESTIONS } from '../constants';
import PrintButton from '../PrintButton';

const ViewMySelectedQuestions = () => {
  const [cookies] = useCookies(['QUESTIONS']);
  const myQuestionList = cookies.QUESTIONS || [];

  const filteredQuestions = filter(QUESTIONS, q => myQuestionList.indexOf(q.id) >= 0);

  return (
    <section>
      <div className='my-selected-questions'>
        {myQuestionList.length > 0 &&
          <div className='email-print-wrap'>
            <PrintButton type='selected-questions' />
          </div>
        }
        <h2>My selected questions</h2>
        {myQuestionList.length < 1 && (
          <div className='empty-state-msg'>
            <span className='image' />
            <p>
              You don&apos;t have any Questions in your question list. Please select some
              questions before printing or saving them.
            </p>
          </div>
        )}
        {filteredQuestions.map(({ id, question }) => <div key={id}>
          <p>{question}</p>
        </div>)}
      </div>
    </section>
  );
};

export default ViewMySelectedQuestions;