import React from 'react';

const PrintButton = ({ type }) => (
  <button
    type='button'
    className='circle circle-print'
    onClick={() => {
      window.print();

      if (type === 'food') {
        printFoodList();
      }
      else if (type === 'selected-questions') {
        printMySelectedQuestions();
      }
      else {
        printMyQuestions();
      }
    }}
  />
);

export default PrintButton;