import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useCookies } from 'react-cookie';
import { CATEGORIES } from '../constants';

const MoreFilters = ({ category, setCategory }) => {
  const [cookies] = useCookies(['MY_FOOD_LIST']);
  const myFoodList = cookies.MY_FOOD_LIST || [];

  const [animate, setAnimate] = useState(false);
  const foodCount = myFoodList.length;

  useEffect(() => {
    setAnimate(true);
  }, [foodCount]);

  return (
    <section className='more-filters'>
      <ul className='tabs'>
        <li className={category === '' ? 'active' : ''}>
          <span
            onClick={e => {
              e.stopPropagation();
              setCategory('');
            }}
          >
            All
          </span>
        </li>
        {Object.keys(CATEGORIES).map(c => (
          <li key={c} className={CATEGORIES[c] === category ? 'active' : ''}>
            <span
              onClick={e => {
                e.stopPropagation();
                setCategory(CATEGORIES[c]);
              }}
            >
              {CATEGORIES[c]}
            </span>
          </li>
        ))}
      </ul>
      <a
        className='food-list-button outline'
        href='/my-food-list'
      >
        <span
          className={`my-food-list-toggle ${
            category === 'SAVED' ? 'active' : ''
          }`}
        >
          View <strong>My Food List</strong>
          <CSSTransition
            classNames={{
              appear: 'count',
              enter: 'animated bounce'
            }}
            in={animate}
            onEntered={() => {
              setAnimate(false);
            }}
            timeout={1000}
          >
            <span className='count'>{foodCount}</span>
          </CSSTransition>
        </span>
      </a>
    </section>
  );
};

export default MoreFilters;

MoreFilters.propTypes = {
  category: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired,
};