import React from 'react';
import PropTypes from 'prop-types';

const FilterModal = ({ open, setFilterOpen, children }) => {
  const modalClassnames = ['modal', 'filter'];

  if (open) {
    modalClassnames.push('active');
  }

  return (
    <div className={modalClassnames.join(' ')}>
      <main>
        <h5>You can <strong>filter</strong> this page to see what foods are <strong>low</strong> or <strong>high</strong> in <strong>sodium, potassium</strong> and <strong>phosphorus</strong>.</h5>
        <button
          type='button'
          className='close-button'
          onClick={() => {
            setFilterOpen(false);
          }}
        />
        {open && children}
      </main>
    </div>
  );
};

export default FilterModal;

FilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setFilterOpen: PropTypes.func.isRequired,
};