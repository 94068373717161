import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Search = ({ setSearchTerm }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value.length > 2) {
      setSearchTerm(value);
    }
    else {
      setSearchTerm('');
    }
  });

  return (
    <div className='food-search search-wrapper'>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <input
          type='search'
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder='Search food'
        />
        {/* <input type='submit' /> */}
      </form>
    </div>
  );
};

export default Search;

Search.propTypes = {
  setSearchTerm: PropTypes.func.isRequired
};