import React from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

const MyListToggle = ({ id, name }) => {
  const [cookies, setCookie] = useCookies(['MY_FOOD_LIST']);
  const myFoodList = cookies.MY_FOOD_LIST || [];

  const buttonClass = [];
  const foodIndex = myFoodList.indexOf(id);
  let buttonLabel = 'Add to My Food List';

  if (foodIndex !== -1) {
    buttonClass.push('checked');
    buttonLabel = 'Remove from My Food List';
  }

  return (
    <section className='add-food'>
      <button
        className={buttonClass.join(' ')}
        onClick={e => {
          e.stopPropagation();

          let newList = [];

          if (foodIndex >= 0) {
            newList = [
              ...myFoodList.slice(0, foodIndex),
              ...myFoodList.slice(foodIndex + 1)
            ];
          }
          else {
            newList = [...myFoodList, id];
            catchFood(name.trim()); // eslint-disable-line
          }

          setCookie('MY_FOOD_LIST', newList, { path: '/' });
        }}
        type='button'
      >
        <span>{buttonLabel}</span>
        <span className='checkbox' />
      </button>
    </section>
  );
};

export default MyListToggle;

MyListToggle.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};