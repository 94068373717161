import React, { useState, useEffect } from 'react';
import Questions from './depression-screener/Questions';
import Results from './depression-screener/Results';

const MentalWellBeing = () => {
  const [ansOne, setAnsOne] = useState(null);
  const [ansTwo, setAnsTwo] = useState(null);
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (result === null) {
      document.getElementById('main').classList.add('questionaire');
    }
    else {
      document.getElementById('main').classList.remove('questionaire');
    }
  });

  const renderContent = () => {
    if (result === null) {
      return (
        <section>
          <Questions
            ansOne={ansOne}
            ansTwo={ansTwo}
            setAnsOne={setAnsOne}
            setAnsTwo={setAnsTwo}
            setResult={setResult}
          />
        </section>
      );
    }

    return (
      <section>
        <Results
          result={result}
          setAnsOne={setAnsOne}
          setAnsTwo={setAnsTwo}
          setResult={setResult}
        />
      </section>
    );
  };

  return <div id='depression-screener'>{renderContent()}</div>;
};

export default MentalWellBeing;