import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import filter from 'lodash/filter';
import { withMyFoodList } from './Root';
import MoreFilters from './foods/MoreFilters';
import List from './foods/List';
import { FOODS } from './constants';
import EmailButton from './email/Foods';
import PrintButton from './PrintButton';

const MyFoodList = () => {
  const [cookies] = useCookies(['MY_FOOD_LIST']);
  const myFoodList = cookies.MY_FOOD_LIST || [];
  const [category, setCategory] = useState('');

  const foods = filter(FOODS.slice(), f => {
    if (category.length > 0) {
      return myFoodList.indexOf(f.id) > -1 && f.category === category;
    }

    return myFoodList.indexOf(f.id) > -1;
  });

  return (
    <section className='my-food-list'>
      <h5>
        You&apos;ve told us that these are the foods you eat regularly or
        would like to discuss with your health care provider. You can edit,
        print, or email this list below. If you&apos;d like to add any items
        to the list, sipmly add them from the{' '}<a href='/living-with-ckd/food-and-diet/common-foods-and-ckd'>common foods</a>{' '}page.
      </h5>

      {myFoodList.length < 1 && (
        <div className='empty-state-msg'>
          <span className='image' />
          <p>
            You don&apos;t have any food in your food list. Visit{' '}
            <a href='/living-with-ckd/food-and-diet/common-foods-and-ckd'>
              Common Foods & CKD
            </a>{' '}
            to find out what foods are low or high in sodium, potassium, and
            phosphorus
          </p>
        </div>
      )}

      {myFoodList.length > 0 && (
        <div className='filters-wrap'>
          <MoreFilters category={category} setCategory={setCategory} />
          <EmailButton />
          <PrintButton type='food' />
        </div>
      )}
      {foods.length > 0 && <List data={foods} />}
    </section>
  );
};

export default withMyFoodList(MyFoodList);