/* eslint-disable max-len */
import unionBy from 'lodash/unionBy';
// imported symptoms
import breathlessness from '../../assets/content/symptoms/breathlessness.md';
import difficultySleeping from '../../assets/content/symptoms/difficulty_sleeping.md';
import itching from '../../assets/content/symptoms/itching.md';
import mySymptomIsNotHere from '../../assets/content/symptoms/my_symptom_is_not_here.md';
import nausea from '../../assets/content/symptoms/nausea.md';
import noSymptoms from '../../assets/content/symptoms/no_symptoms.md';
import poorAppetite from '../../assets/content/symptoms/poor_appetite.md';
import tasteChanges from '../../assets/content/symptoms/taste_changes.md';
import tiredness from '../../assets/content/symptoms/tiredness.md';
// imported prescribed medications
import medsBloodPressure from '../../assets/content/medications/prescription/blood_pressure.md';
import medsDiabetes from '../../assets/content/medications/prescription/diabetes.md';
import medsCholesterol from '../../assets/content/medications/prescription/cholesterol.md';
import medsDiuretics from '../../assets/content/medications/prescription/diuretics.md';
import medsLowRedBloodCells from '../../assets/content/medications/prescription/low_red_blood_cells.md';
import medsHighPotassium from '../../assets/content/medications/prescription/high_potassium.md';
import medsAcidBaseImbalance from '../../assets/content/medications/prescription/acid_base_imbalance.md';
// imported non prescribed medications
import medsColdsCoughs from '../../assets/content/medications/non-prescription/colds_coughs.md';
import medsCongestion from '../../assets/content/medications/non-prescription/congestion.md';
import medsConstipation from '../../assets/content/medications/non-prescription/constipation.md';
import medsDiarrhea from '../../assets/content/medications/non-prescription/diarrhea.md';
import medsFeverPain from '../../assets/content/medications/non-prescription/fever_pain.md';
import medsHeadache from '../../assets/content/medications/non-prescription/headache.md';
import medsIndigestion from '../../assets/content/medications/non-prescription/indigestion.md';
import medsMuscleAches from '../../assets/content/medications/non-prescription/muscle_aches.md';
import medsNausea from '../../assets/content/medications/non-prescription/nausea.md';
import fruits from '../data/fruits.json';
import vegetables from '../data/vegetables.json';
import beansNutsLegumes from '../data/beans_nuts_legumes.json';
import meatFishEggs from '../data/meat_fish_eggs.json';
import grains from '../data/grains.json';
import dairy from '../data/dairy.json';
import seasoningsToppings from '../data/seasonings_toppings.json';
import fatsOils from '../data/fats_oils.json';
import questions from '../data/questions.json';

export const LEVELS = {
  LOW: 'low',
  MID: 'moderate',
  HIGH: 'high'
};

export const CATEGORIES = {
  VEGETABLES: 'Vegetables',
  BEANS_NUTS_LEGUMES: 'Beans, Nuts & Legumes',
  FRUIT: 'Fruit',
  MEAT_FISH: 'Meat, Fish & Eggs',
  GRAINS: 'Grain',
  DAIRY: 'Dairy',
  SEASONINGS_TOPINGS: 'Seasonings & Toppings',
  FATS_OILS: 'Fats & Oils'
};

export const CATEGORY_INFORMATION = {
  ALL:
  'If you need to reduce the amount of phosphorus in your diet, it is important to limit processed foods instead of fresh foods. Your body absorbs more phosphorus from processed foods than from fresh foods.',
  BEANS_NUTS_LEGUMES:
  'If you need to reduce the amount of phosphorus in your diet, it is important to limit processed foods instead of fresh foods. Your body absorbs more phosphorus from processed foods than from fresh foods.',
  MEAT_FISH:
  'If you need to reduce the amount of phosphorus in your diet, it is important to limit processed foods (such as deli meats and bacon) instead of fresh foods. Your body absorbs more phosphorus from processed foods than from fresh foods. Phosphorus is not reported for meat products because of differences in how your body absorbs phosphorus from processed versus fresh foods.',
  GRAINS:
  'If you need to reduce the amount of phosphorus in your diet, it is important to limit processed foods instead of fresh foods. Your body absorbs more phosphorus from processed foods than from fresh foods.',
  DAIRY:
  'If you need to reduce the amount of phosphorus in your diet, it is important to limit processed foods instead of fresh foods. Your body absorbs more phosphorus from processed foods than from fresh foods.'
};

export const PORTIONS = {
  PALM: 'palm',
  FIST: 'fist',
  HALF_FIST: 'half-fist',
  THUMB: 'thumb',
  TWO_THUMBS: 'two-thumbs',
  THUMB_TIP: 'thumb-tip',
  TWO_HANDFULS: 'two-handfuls',
  HANDFUL: 'handful',
  CUPPED_HAND: 'cupped-hand',
};

export const FOODS = unionBy([].concat(fruits,
  vegetables,
  beansNutsLegumes,
  meatFishEggs,
  grains,
  dairy,
  seasoningsToppings,
  fatsOils).map(f => ({
  ...f,
  uuid: f.id.replace(/([0-9]+)_.+/, '$1'), // Used to avoid duplicates
  sodium: f.sodium.toLowerCase(),
  potassium: f.potassium.toLowerCase(),
  phosphorus: f.phosphorus.toLowerCase(),
  portion: f.portion
    .toLowerCase()
    .replace('cupped hand', PORTIONS.CUPPED_HAND)
    .replace('1 fist', PORTIONS.FIST)
    .replace('1/2 fist', PORTIONS.HALF_FIST)
    .replace('2 thumbs', PORTIONS.TWO_THUMBS)
    .replace('thumb tip', PORTIONS.THUMB_TIP),
  sodiumMg: Number(f.sodiumMg) || 0,
  potassiumMg: Number(f.potassiumMg) || 0,
  phosphorusMg: Number(f.phosphorusMg) || 0,
})), 'uuid');

export const QUESTIONS = questions;

export const STAGES = {
  ALL: 'All',
  STAGE_1: 'Stage 1 (eGFR greater than 90)',
  STAGE_2: 'Stage 2 (eGFR 60-89)',
  STAGE_3: 'Stage 3 (eGFR 30-59)',
  STAGE_4: 'Stage 4 (eGFR 15-29)',
  STAGE_5: 'Stage 5 (eGFR less than 15)'
};

export const SYMPTOMS = [
  {
    id: 'noSymptoms',
    name: 'No Symptoms',
    stages: ['STAGE_1', 'STAGE_2'],
    info: noSymptoms
  },
  {
    id: 'tiredness',
    name: 'Tiredness',
    stages: ['STAGE_3', 'STAGE_4', 'STAGE_5'],
    info: tiredness
  },
  {
    id: 'poorAppetite',
    name: 'Poor Appetite',
    stages: ['STAGE_3', 'STAGE_4', 'STAGE_5'],
    info: poorAppetite
  },
  {
    id: 'itchy',
    name: 'Itchy',
    stages: ['STAGE_3', 'STAGE_4', 'STAGE_5'],
    info: itching
  },
  {
    id: 'tasteChanges',
    name: 'Taste Changes',
    stages: ['STAGE_5'],
    info: tasteChanges
  },
  {
    id: 'nausea',
    name: 'Nausea',
    stages: ['STAGE_5'],
    info: nausea
  },
  {
    id: 'difficultySleeping',
    name: 'Difficulty Sleeping',
    stages: ['STAGE_5'],
    info: difficultySleeping
  },
  {
    id: 'breathlessness',
    name: 'Breathlessness',
    stages: ['STAGE_5'],
    info: breathlessness
  },
  {
    id: 'mySymptomIsNotHere',
    name: 'My Symptom isn\'t here',
    stages: ['STAGE_1', 'STAGE_2', 'STAGE_3', 'STAGE_4', 'STAGE_5'],
    info: mySymptomIsNotHere
  }
];

export const PRESCRIPTION_MEDICATIONS = [
  {
    id: 'blood_pressure',
    name: 'Blood Pressure',
    info: medsBloodPressure
  },
  {
    id: 'diabetes',
    name: 'Diabetes',
    info: medsDiabetes
  },
  {
    id: 'cholesterol',
    name: 'Cholesterol',
    info: medsCholesterol
  },
  {
    id: 'diuretics',
    name: 'Diuretics',
    info: medsDiuretics
  },
  {
    id: 'low_red_blood_cells',
    name: 'Low Red Blood Cells',
    info: medsLowRedBloodCells
  },
  {
    id: 'high_potassium',
    name: 'High Potassium',
    info: medsHighPotassium
  },
  {
    id: 'acid_base_imbalance',
    name: 'Acid/Base Imbalance',
    info: medsAcidBaseImbalance
  }
  /*
   * {
   *   id: 'sick_days',
   *   name: 'Sick Days',
   *   info: meds_si
   * }
   */
];

export const NON_PRESCRIPTION_MEDICATIONS = [
  {
    id: 'headache',
    name: 'Headache',
    info: medsHeadache
  },
  {
    id: 'colds_coughs',
    name: 'Colds/Coughs',
    info: medsColdsCoughs
  },
  {
    id: 'congestion',
    name: 'Congestion',
    info: medsCongestion
  },
  {
    id: 'muscle_aches',
    name: 'Muscle aches',
    info: medsMuscleAches
  },
  {
    id: 'fever_pain',
    name: 'Fever/Pain',
    info: medsFeverPain
  },
  {
    id: 'indigestion',
    name: 'Indigestion',
    info: medsIndigestion
  },
  {
    id: 'constipation',
    name: 'Constipation',
    info: medsConstipation
  },
  {
    id: 'diarrhea',
    name: 'Diarrhea',
    info: medsDiarrhea
  },
  {
    id: 'nausea',
    name: 'Nausea',
    info: medsNausea
  }
];

export const DEPRESSION_SCREENER_RESULTS = [
  {
    lowerThreshold: 0,
    upperThreshold: 3,
    title: 'Major Depressive Disorder is Not Likely',
    description:
      'It is unlikely that you have depression. However, if any symptoms that you are experiencing are affecting your life, you should talk to your health care provider.'
  },
  {
    lowerThreshold: 4,
    upperThreshold: 6,
    title: 'Major Depressive Disorder is Likely',
    description:
      'You may be experiencing depression. Talking to your health care provider is strongly recommended.'
  }
];

export const FUSE_OPTIONS = {
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 50,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  findAllMatches: true,
  keys: ['name']
};