import React from 'react';
import PropTypes from 'prop-types';
import { DEPRESSION_SCREENER_RESULTS } from '../constants';

const Results = ({ result, setResult, setAnsOne, setAnsTwo }) => {
  const results = DEPRESSION_SCREENER_RESULTS.find(({ lowerThreshold, upperThreshold }) =>
    result >= lowerThreshold && result <= upperThreshold);

  return (
    <div>
      <h2>Results</h2>

      <div className='results-box'>
        <div className='results'>
          <div className='score'>
            <h5>Score</h5>
            <span>{result}</span>
          </div>
          <div className='info'>
            <h4>{results.title}</h4>
            <p>{results.description}</p>
          </div>
        </div>

        <button
          className='button'
          onClick={() => {
            setResult(null);
            setAnsOne(null);
            setAnsTwo(null);
          }}
          type='button'
        >
          Retake the Depression Screener
        </button>
      </div>

      <h5>Remember</h5>
      <p>
        For your privacy your result won&apos;t be stored in your browser
        cookies, and won&apos;t be saved if you navigate away from the results
        page.
      </p>
      <p>
        The purpose of this screener (PHQ-2) is <strong>not</strong> to diagnose
        or monitor depression severity. This PHQ-2 screener is meant to be a
        “first step” approach to recommend you next steps. A score of 3 or less
        indicates it is unlikely that you have depression. However, if any
        symptoms that you are experiencing are affecting your life, you should
        talk to your health care provider. A score of 4 or more you may be
        experiencing depression. Talking to your health care provider is
        strongly recommended.
      </p>
    </div>
  );
};

export default Results;

Results.propTypes = {
  result: PropTypes.number.isRequired,
  setAnsOne: PropTypes.func.isRequired,
  setAnsTwo: PropTypes.func.isRequired,
  setResult: PropTypes.func.isRequired
};