import React from 'react';
import PropTypes from 'prop-types';

const MineralMetre = ({
  phosphorus,
  potassium,
  sodium,
  phosphorusMg,
  potassiumMg,
  sodiumMg,
  category
}) => (
  <div className='mineral-metre-wrap'>
    <div className='mineral-metre'>
      <span className='label'>Sodium</span>
      <span className='grams'>{`${sodiumMg} mg`}</span>
      <div className={`pills ${sodium}`}>
        <span />
        <span />
        <span />
      </div>
    </div>
    <div className='mineral-metre'>
      <span className='label'>Potassium</span>
      <span className='grams'>{`${potassiumMg} mg`}</span>
      <div className={`pills ${potassium}`}>
        <span />
        <span />
        <span />
      </div>
    </div>
    <div className='mineral-metre' style={category === 'Meat, Fish & Eggs' ? { visibility: 'hidden' } : {}}>
      <span className='label'>Phosphorus</span>
      <span className='grams'>{`${phosphorusMg} mg`}</span>
      <div className={`pills ${phosphorus}`}>
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
);

export default MineralMetre;

MineralMetre.propTypes = {
  phosphorus: PropTypes.string.isRequired,
  potassium: PropTypes.string.isRequired,
  sodium: PropTypes.string.isRequired,
  phosphorusMg: PropTypes.number,
  potassiumMg: PropTypes.number,
  sodiumMg: PropTypes.number,
  category: PropTypes.string.isRequired
};

MineralMetre.defaultProps = {
  phosphorusMg: 0,
  potassiumMg: 0,
  sodiumMg: 0
};