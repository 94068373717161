import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const MedicationsModal = ({ id, name, info }) => {
  const [isActive, setIsActive] = useState(window.location.href.includes(`#${id}`));

  const modalClassnames = ['modal'];

  if (isActive) {
    modalClassnames.push('active');
  }

  useEffect(() => {
    const handleHashChange = () => {
      setIsActive(window.location.href.includes(`#${id}`));
    };

    window.addEventListener('hashchange', handleHashChange);

    if (isActive) {
      catchModal(`${document.location.pathname}#${id}`, name); // eslint-disable-line
    }

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  });

  const openModal = () => {
    window.location.hash = `#${id}`;
  };

  const closeModal = () => {
    window.location.hash = 'all';
  };

  return (
    <div className='item'>
      <button key={id} onClick={() => openModal()} type='button'>
        <img src={`/medication_icons/${id}.png`} />
        <h5>{name}</h5>
        <hr />
        <p>
          <img src='/info.png' />
          <span>See Details</span>
        </p>
      </button>

      <div className={modalClassnames.join(' ')}>
        <div className='info-container'>
          <span className='close-button' onClick={() => closeModal()} />
          <h2>
            <img src={`/medication_icons/${id}.png`} />
            {name}
          </h2>
          <div className='md-content' dangerouslySetInnerHTML={{ __html: info}} />
        </div>
      </div>
    </div>
  );
};

export default MedicationsModal;

MedicationsModal.propTypes = {
  id: PropTypes.string,
  info: PropTypes.string,
  name: PropTypes.string
};