import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

const Disclaimer = () => {
  const [isActive, setIsActive] = useState(true);
  const [cookies, setCookie] = useCookies(['QUESTIONS']);
  const isDisclaimerAccepted = cookies.DISCLAIMER || false;
  const disclaimerClassnames = ['disclaimer-container', 'slide-up'];

  if (!isActive) {
    disclaimerClassnames.push('slide-down');
  }

  if (isDisclaimerAccepted === false) {
    return (
      <div className={disclaimerClassnames.join(' ')}>
        <div className='disclaimer'>
          <div className='content'>
            <button
              className='close-button'
              type='button'
              onClick={() => {
                setCookie('DISCLAIMER', true, { path: '/' });
                setIsActive(false);
              }}
            />
            <p>
              The information you save on this website is not permanent. If you
              clear your cookies, or view the site on another device or web
              browser, your saved information will not be available. If another
              person views the website on this device, they will see the
              information you have saved.
            </p>
            <p>
              The information provided on My Kidneys My Health is designed to
              complement, not replace, the relationship between a patient and
              their health care provider(s).
            </p>
          </div>
        </div>
      </div>
    );
  }

  return <div className={disclaimerClassnames.join(' ')} />;
};

export default Disclaimer;