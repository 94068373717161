import React from 'react';
import { useCookies } from 'react-cookie';
import { FOODS } from '../constants';
import MessageModal from './MessageModal';

const Foods = () => {
  const [cookies] = useCookies(['MY_FOOD_LIST']);
  const foodNames = cookies.MY_FOOD_LIST || [];

  const filteredFoods = FOODS.filter(f => {
    if (foodNames.indexOf(f.id) >= 0) {
      return f;
    }
  });

  const foodList = filteredFoods.map(({ name, sodiumMg, potassiumMg, phosphorusMg, serving, portion }) =>
    // eslint-disable-next-line max-len
    encodeURIComponent(`${name}\r\nServing Size: ${serving}\r\nSize of: ${portion}\r\nSodium: ${sodiumMg} mg\r\nPotassium: ${potassiumMg} mg\r\nPhosphorus: ${phosphorusMg} mg\r\n\r\n`));

  const newList = foodList.join(' ');
  const subject = 'My Kidneys My Health: My Food List';

  return (
    <MessageModal dataString={newList} subjectString={subject} type='food' />
  );
};

export default Foods;