import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MineralMetre from './MineralMetre';
import MyListToggle from './MyListToggle';

const Modal = ({ data, open, setSelected, placeholderImage }) => {
  if (!data) {
    return false;
  }

  const modalClassnames = ['modal'];

  if (open) {
    modalClassnames.push('active');
  }

  const { id, name, phosphorus, potassium, sodium } = data;

  return (
    <div className={modalClassnames.join(' ')}>
      <div className='food-modal'>
        <span
          className='close-button'
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            window.location.hash = 'all';
            setSelected('');
          }}
        />
        <figure className='pad'>
          <div className='image'>
            <img alt={name} src={placeholderImage} />
          </div>
          <figcaption>
            <h2>{name}</h2>
            <MyListToggle id={id} />
            <MineralMetre {...data} />
          </figcaption>
        </figure>
        <article className='pad'>
          <h4>{`Why eat ${name.toLowerCase()}?`}</h4>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Reiciendis, explicabo mollitia. Voluptates aliquid quam aliquam.
            Recusandae voluptatem aliquam ipsam fugiat dolor odit obcaecati
            eveniet quaerat voluptates dicta, similique, optio itaque?
          </p>
        </article>

        <hr />

        <article className='pad serving-size'>
          <div className='image' />
          <div>
            <h4>Serving size</h4>
            <p>
              An average serving size of Carrots is 14g. For most people this is
              approximately a handful.
            </p>
          </div>
        </article>

        <section className='alternatives'>
          <h4>Alternatives</h4>
          <div className='pill-wrap'>
            {['asparagus', 'celery', 'broccoli'].map(f => (
              <button
                className='pill'
                key={f}
                onClick={e => {
                  e.preventDefault();
                  window.location.hash = f;
                  setSelected(f);
                }}
                type='button'
              >
                <span className='image'>
                  <img alt={f} src={placeholderImage} />
                </span>
                <span className='name'>{f}</span>
                <span className='right-arrow' />
              </button>
            ))}
          </div>
        </section>

        <hr />

        <section className='pad common-questions'>
          <h4>Common questions for my healthcare team</h4>

          <p>What kind of foods should I specifically be avoiding?</p>

          <p>Is there something I eat that I should stop eating?</p>
        </section>
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    phosphorus: PropTypes.string,
    potassium: PropTypes.string,
    sodium: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  data: null,
};