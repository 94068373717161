import React, { useState } from 'react';
import PropTypes from 'prop-types';

const getNewState = (arr, str) => {
  const index = arr.indexOf(str);

  if (index > -1) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  }

  return [...arr, str];
};

const Filters = props => {
  const [level, setLevel] = useState(props.level);
  const [nutrients, setNutrients] = useState(props.minerals);

  const sodiumIndex = nutrients.indexOf('sodium');
  const potassiumIndex = nutrients.indexOf('potassium');
  const phosphorusIndex = nutrients.indexOf('phosphorus');

  return (
    <section className='food-filters'>
      <div className='toggles'>
        <p>
          <span>I want to know what foods are</span>
          <button
            type='button'
            className={[
              'button',
              'success',
              'rounded',
              'radio',
              level === 'low' ? 'checked' : 'outline'
            ].join(' ')}
            onClick={() => {
              setLevel('low');
            }}
          >
            low
          </button>
          <button
            type='button'
            className={[
              'button',
              'danger',
              'rounded',
              'radio',
              level === 'high' ? 'checked' : 'outline'
            ].join(' ')}
            onClick={() => {
              setLevel('high');
            }}
          >
            high
          </button>
        </p>

        <p>
          <span>In these nutrients</span>
          <button
            type='button'
            className={[
              'button',
              'primary',
              'rounded',
              'checkbox',
              sodiumIndex < 0 && 'outline'
            ].join(' ')}
            onClick={() => {
              setNutrients(getNewState(nutrients, 'sodium'));
            }}
          >
            Sodium
          </button>
          <button
            type='button'
            className={[
              'button',
              'primary',
              'rounded',
              'checkbox',
              potassiumIndex < 0 && 'outline'
            ].join(' ')}
            onClick={() => {
              setNutrients(getNewState(nutrients, 'potassium'));
            }}
          >
            Potassium
          </button>
          <button
            type='button'
            className={[
              'button',
              'primary',
              'rounded',
              'checkbox',
              phosphorusIndex < 0 && 'outline'
            ].join(' ')}
            onClick={() => {
              setNutrients(getNewState(nutrients, 'phosphorus'));
            }}
          >
            Phosphorus
          </button>
        </p>
      </div>

      <div className='legend-container'>
        <span>Legend</span>
        <div className='legend'>
          <div className='pills'>
            <p className='text'>low</p>
            <span className='low' />
          </div>
          <div className='pills'>
            <p className='text'>moderate</p>
            <span className='moderate' />
          </div>
          <div className='pills'>
            <p className='text'>high</p>
            <span className='high' />
          </div>
        </div>
      </div>

      <footer>
        <span
          className='reset-toggle'
          onClick={() => {
            props.setLevel('');
            props.setMinerals([]);
            setLevel('');
            setNutrients([]);
          }}
        >
          <span>Reset Filter</span>
        </span>
        <button
          type='button'
          className='primary'
          onClick={() => {
            props.setLevel(level);
            props.setMinerals(nutrients);
            props.setFilterOpen(false);
          }}
        >
          Filter
        </button>
      </footer>
    </section>
  );
};

export default Filters;

Filters.propTypes = {
  level: PropTypes.string.isRequired,
  minerals: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLevel: PropTypes.func.isRequired,
  setMinerals: PropTypes.func.isRequired,
  setFilterOpen: PropTypes.func.isRequired
};