import React, { useState } from 'react';
import PropTypes from 'prop-types';
import playButton from '../../assets/images/play_button.png';

const VideoModal = ({ isActive: initActive, src, label }) => {
  const [isActive, setIsActive] = useState(initActive);
  const modalClassnames = ['modal'];

  if (isActive) {
    modalClassnames.push('active');
  }

  return (
    <div>
      <button onClick={() => {
        setIsActive(true);
        // eslint-disable-next-line no-undef
        catchVideo(label);
      }} type='button'>
        <img alt='Play' src={playButton} />
        Watch the video
      </button>
      <div
        className={modalClassnames.join(' ')}
        onClick={() => setIsActive(false)}
      >
        {isActive && (
          <div className='video-wrapper'>
            <iframe
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              frameBorder='0'
              height='315'
              src={src}
              width='560'
            />
          </div>
        )}
        <span className='close-button' />
      </div>
    </div>
  );
};

export default VideoModal;

VideoModal.propTypes = {
  isActive: PropTypes.bool,
  src: PropTypes.string.isRequired,
  label: PropTypes.string,
};

VideoModal.defaultProps = {
  isActive: false,
  label: ''
};