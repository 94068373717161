import React from 'react';
import { useCookies } from 'react-cookie';
import pages from '../../data/pages.json';
import questions from '../../data/questions.json';
import ckdIcon from '../../../assets/images/nav-icons/what-is-ckd_nav.png';
import foodDietIcon from '../../../assets/images/nav-icons/food-diet_nav.png';
import medicationsIcon from '../../../assets/images/nav-icons/medications_nav.png';
import symptomsIcon from '../../../assets/images/nav-icons/symptoms_nav.png';
import mentalIcon from '../../../assets/images/nav-icons/mental_nav.png';
import physicalIcon from '../../../assets/images/nav-icons/physical_nav.png';
import financesIcon from '../../../assets/images/nav-icons/finances_nav.png';
import wordEduIcon from '../../../assets/images/nav-icons/work-edu_nav.png';
import travelIcon from '../../../assets/images/nav-icons/travel_nav.png';

const AllQuestions = () => {
  const [cookies, setCookie] = useCookies(['QUESTIONS']);
  const myQuestionList = cookies.QUESTIONS || [];
  const filteredPageQuestions = pageId => questions.filter(question => question.pages.includes(pageId));

  const pageIcons = {
    ckd: ckdIcon,
    foodDiet: foodDietIcon,
    medications: medicationsIcon,
    symptoms: symptomsIcon,
    mental: mentalIcon,
    physical: physicalIcon,
    finances: financesIcon,
    wordEdu: wordEduIcon,
    travel: travelIcon,
  };

  return (
    <section>
      <div className='all-questions'>
        {pages.map(page => (
          <details key={page.id}>
            <summary className='all-questions-summary'>
              <img className='page-icon' src={pageIcons[page.icon]} alt='' />
              <span className='summary-title'>{page.questions_page_heading}</span>
              <div className='summary-chevron-up'>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'
                  stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'
                  className='feather feather-chevron-down'>
                  <polyline points='6 9 12 15 18 9' />
                </svg>
              </div>
            </summary>
            <div className='summary-content questions-container'>
              {filteredPageQuestions(page.id).map(({ id, question }) => {
                const questionIndex = myQuestionList.indexOf(id);
                const isChecked = myQuestionList.indexOf(id) >= 0;
                return (
                  <div className='all-question-item' key={id}>
                    <p>{question}</p>
                    <label>
                      <input
                        checked={isChecked}
                        onChange={() => {
                          let newList = [];

                          if (isChecked) {
                            newList = [
                              ...myQuestionList.slice(0, questionIndex),
                              ...myQuestionList.slice(questionIndex + 1)
                            ];
                          }
                          else {
                            newList = [...myQuestionList, id];
                          }

                          setCookie('QUESTIONS', newList, { path: '/' });
                        }}
                        type='checkbox'
                        value={id}
                      />
                      <span>
                        {isChecked ? 'Remove from' : 'Add to'} My Questions List
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className='summary-chevron-down'>
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'
                stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'
                className='feather feather-chevron-up'>
                <polyline points='18 15 12 9 6 15' />
              </svg>
            </div>
          </details>
        ))}
      </div>
    </section>
  );
};

export default AllQuestions;