import React, { useState } from 'react';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import findKey from 'lodash/findKey';
import has from 'lodash/has';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import mapValues from 'lodash/mapValues';
import without from 'lodash/without';
import values from 'lodash/values';
import { useCookies } from 'react-cookie';
import Fuse from 'fuse.js';
import { withMyFoodList } from './Root';
import { LEVELS, FOODS, FUSE_OPTIONS, CATEGORIES, CATEGORY_INFORMATION } from './constants';
import Filters from './foods/Filters';
import MoreFilters from './foods/MoreFilters';
import List from './foods/List';
import FoodInfo from './foods/FoodInfo';
import Search from './foods/Search';
import FilterModal from './foods/FilterModal';

export const SavedFoods = React.createContext([]);

const LEVEL_SCORE = {
  [LEVELS.LOW]: 1,
  [LEVELS.MID]: 2,
  [LEVELS.HIGH]: 3,
};

const defaultCategoryAlert = mapValues(CATEGORY_INFORMATION, () => true);

// eslint-disable-next-line max-statements
const FoodDiet = () => {
  const [cookies] = useCookies(['MY_FOOD_LIST']);
  const myFoodList = cookies.MY_FOOD_LIST || [];
  const [filterOpen, setFilterOpen] = useState(false);
  const [level, setLevel] = useState('');
  const [minerals, setMinerals] = useState([]);
  const [category, setCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryAlert, setCategoryAlert] = useState(defaultCategoryAlert);
  let foods = FOODS.slice();

  if (searchTerm.length > 0) {
    const fuse = new Fuse(foods, FUSE_OPTIONS);
    foods = fuse.search(searchTerm).map(r => r.item);
  }

  if (minerals.length > 0 && level.length > 0) {
    foods = filter(FOODS, f => {
      // Include medium level...
      const unselected = without(values(pick(f, minerals)), level, LEVELS.MID);
      return unselected.length === 0;
    });

    if (searchTerm.length > 0) {
      const fuse = new Fuse(foods, FUSE_OPTIONS);
      foods = fuse.search(searchTerm).map(r => r.item);
    }
  }

  // Filter by category only if there's no search term.
  if (category.length > 0 && searchTerm.length === 0) {
    if (category === 'SAVED') {
      foods = filter(foods.slice(), f => myFoodList.indexOf(f.id) > -1);
    }
    else {
      foods = filter(foods.slice(), f => f.category.indexOf(category) >= 0);
    }
  }

  if (minerals.length > 0 && level.length > 0) {
    if (minerals.length === 1) {
      foods = orderBy(foods, [
        f => f[`${minerals[0]}Mg`],
        'name',
      ], [
        LEVELS.LOW === level ? 'asc' : 'desc',
        'asc',
      ]);
    }
    else {
      foods.sort((a, b) => {
        const asum = sum(minerals.map(m => LEVEL_SCORE[a[m]] || 0));
        const bsum = sum(minerals.map(m => LEVEL_SCORE[b[m]] || 0));

        return (LEVELS.LOW === level) ? asum - bsum : bsum - asum;
      });
    }
  }
  /*
   * else {
   *   foods = orderBy(foods, ['name'], ['asc']);
   * }
   */

  let categoryKey = '';

  if (category === '') {
    categoryKey = 'ALL';
  }
  else {
    categoryKey = findKey(CATEGORIES, c => c === category);
  }

  return (
    <section className='food-and-diet'>
      <section className='filter-search-wrap'>
        <div className='toggle-wrap'>
          <button type='button' className='filter-toggle' onClick={() => {
            setFilterOpen(!filterOpen);
          }}>
            <span>Filter foods by <strong>nutrient content</strong></span>
          </button>
          <span
            className='reset-toggle'
            onClick={() => {
              setLevel('');
              setMinerals([]);
            }}
          >
            <span>Reset Filter</span>
          </span>
        </div>
        <Search setSearchTerm={setSearchTerm} />
      </section>
      <MoreFilters
        category={category}
        setCategory={setCategory}
      />
      <FoodInfo
        categoryKey={categoryKey}
        categoryAlert={categoryAlert}
        setCategoryAlert={setCategoryAlert}
      />
      <FilterModal
        open={filterOpen}
        setFilterOpen={setFilterOpen}
      >
        <Filters
          level={level}
          minerals={minerals}
          setLevel={setLevel}
          setMinerals={setMinerals}
          setFilterOpen={setFilterOpen}
        />
      </FilterModal>
      <div className='legend-outer'>
        <div className='legend'>
          <div className='pills'>
            <span className='low' />
            <p className='text'>Low</p>
          </div>
          <div className='pills'>
            <span className='moderate' />
            <p className='text'>Med</p>
          </div>
          <div className='pills'>
            <span className='high' />
            <p className='text'>High</p>
          </div>
        </div>
      </div>
      <List data={foods} showImage />
    </section>
  );
};

export default withMyFoodList(FoodDiet);