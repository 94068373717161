import React from 'react';
import { useCookies } from 'react-cookie';
import { QUESTIONS } from '../constants';
import MessageModal from './MessageModal';

const Questions = () => {
  const [cookies] = useCookies(['QUESTIONS']);
  const questionIDs = cookies.QUESTIONS || [];

  const filteredQuestions = QUESTIONS.filter(q => {
    if (questionIDs.indexOf(q.id) >= 0) {
      return q.question;
    }
  });

  const questionsList = filteredQuestions.map(({ question }) =>
    encodeURIComponent(`${question}\r\n\r\n`));

  const newList = questionsList.join(' ');
  const subject = 'My Kidneys My Health: My Questions List';

  return (
    <MessageModal dataString={newList} subjectString={subject} />
  );
};

export default Questions;