import React from 'react';
import questions from '../../data/questions.json';
import PrintButton from '../PrintButton';

const PrintAllQuestions = () => (
  <section>
    <div className='my-selected-questions'>
      <div className='email-print-wrap'>
        <PrintButton type='selected-questions' />
      </div>
      <h2>All questions</h2>
      {questions.map(({ id, question }) => <p key={id}>{question}</p>)}
    </div>
  </section>
);

export default PrintAllQuestions;