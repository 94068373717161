import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Questions = ({
  ansOne,
  setAnsOne,
  ansTwo,
  setAnsTwo,
  setResult
}) => {
  const [errMsg, setErrMsg] = useState('');

  const calculateResults = () => {
    if (ansOne === null || ansTwo === null) {
      setResult(null);
      setErrMsg('Please select a response to this question.');
    }
    else {
      setResult(ansOne + ansTwo);
      // eslint-disable-next-line no-undef
      catchDepressionScreen('complete');
    }

    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className='depression-screener'>
        <h5>Over the past 2 weeks, how often have you been bothered by any of the following problems:</h5>
        <div className='question'>
          <h5>
            1. Little interest or pleasure in doing things?
          </h5>
          <span>{ansOne === null ? errMsg : ''}</span>
          <div className='answer-row'>
            <button
              className={[
                'button',
                'primary',
                'rounded',
                'radio',
                ansOne === 0 ? 'checked' : 'outline'
              ].join(' ')}
              onClick={() => {
                setAnsOne(0);
              }}
              type='button'
            >
              Not At All
            </button>
            <button
              className={[
                'button',
                'primary',
                'rounded',
                'radio',
                ansOne === 1 ? 'checked' : 'outline'
              ].join(' ')}
              onClick={() => {
                setAnsOne(1);
              }}
              type='button'
            >
              Several Days
            </button>
            <button
              className={[
                'button',
                'primary',
                'rounded',
                'radio',
                ansOne === 2 ? 'checked' : 'outline'
              ].join(' ')}
              onClick={() => {
                setAnsOne(2);
              }}
              type='button'
            >
              More Than Half the Days
            </button>
            <button
              className={[
                'button',
                'primary',
                'rounded',
                'radio',
                ansOne === 3 ? 'checked' : 'outline'
              ].join(' ')}
              onClick={() => {
                setAnsOne(3);
              }}
              type='button'
            >
              Nearly Every Day
            </button>
          </div>
        </div>
        <div className='question'>
          <h5>
            2. Feeling down, depressed or hopeless?
          </h5>
          <span>{ansTwo === null ? errMsg : ''}</span>
          <div className='answer-row'>
            <button
              className={[
                'button',
                'primary',
                'rounded',
                'radio',
                ansTwo === 0 ? 'checked' : 'outline'
              ].join(' ')}
              onClick={() => {
                setAnsTwo(0);
              }}
              type='button'
            >
              Not At All
            </button>
            <button
              className={[
                'button',
                'primary',
                'rounded',
                'radio',
                ansTwo === 1 ? 'checked' : 'outline'
              ].join(' ')}
              onClick={() => {
                setAnsTwo(1);
              }}
              type='button'
            >
              Several Days
            </button>
            <button
              className={[
                'button',
                'primary',
                'rounded',
                'radio',
                ansTwo === 2 ? 'checked' : 'outline'
              ].join(' ')}
              onClick={() => {
                setAnsTwo(2);
              }}
              type='button'
            >
              More Than Half the Days
            </button>
            <button
              className={[
                'button',
                'primary',
                'rounded',
                'radio',
                ansTwo === 3 ? 'checked' : 'outline'
              ].join(' ')}
              onClick={() => {
                setAnsTwo(3);
              }}
              type='button'
            >
              Nearly Every Day
            </button>
          </div>
        </div>
        <button
          className='button primary rounded'
          onClick={() => {
            calculateResults();
          }}
          type='button'
        >
          Calculate Results
        </button>
      </div>
      <p>
        * For your privacy your result won&apos;t be stored in your browser
        cookies, and won&apos;t be saved if you navigate away from the results
        page.
      </p>
    </div>
  );
};

export default Questions;

Questions.propTypes = {
  ansOne: PropTypes.number,
  ansTwo: PropTypes.number,
  setAnsOne: PropTypes.func.isRequired,
  setAnsTwo: PropTypes.func.isRequired,
  setResult: PropTypes.func.isRequired
};