import React from 'react';
import PropTypes from 'prop-types';
import SymptomsModal from './SymptomsModal';
import { SYMPTOMS } from '../constants';

const List = ({ stage }) => {
  let filteredSymptoms = SYMPTOMS.slice();

  if (stage !== 'ALL') {
    filteredSymptoms = SYMPTOMS.filter(({ stages }) => stages.indexOf(stage) >= 0);
  }

  return (
    <section className='symptoms-list'>
      {filteredSymptoms.map(({ id, name, info }) =>
        <SymptomsModal id={id} info={info} key={id} name={name} />)}
    </section>
  );
};

export default List;

List.propTypes = {
  stage: PropTypes.string
};