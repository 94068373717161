import React from 'react';
import PropTypes from 'prop-types';
import { CATEGORY_INFORMATION } from '../constants';

const FoodInfo = ({ categoryKey, categoryAlert, setCategoryAlert }) => {
  const open = categoryAlert[categoryKey];
  const classNames = ['category-information'];
  let message = '';

  if (categoryKey) {
    message = CATEGORY_INFORMATION[categoryKey];
  }

  if (!open) {
    classNames.push('display-none');
  }

  return (
    <div className={classNames.join(' ')}>
      <span className='image' />
      <p>{message}</p>
      <button
        className='close-button'
        type='button'
        onClick={() => {
          setCategoryAlert({
            ...categoryAlert,
            [categoryKey]: !open
          });
        }}
      />
    </div>
  );
};

export default FoodInfo;

FoodInfo.propTypes = {
  categoryKey: PropTypes.string,
  categoryAlert: PropTypes.object.isRequired,
  setCategoryAlert: PropTypes.func.isRequired
};

FoodInfo.defaultPropTypes = {
  categoryKey: ''
};