import React from 'react';
import MedicationsModal from './MedicationsModal';
import { PRESCRIPTION_MEDICATIONS } from '../constants';

const Prescription = () => {
  const medications = PRESCRIPTION_MEDICATIONS.slice();

  return (
    <section className='items-wrapper'>
      <div className='list'>
        {medications.map(({ id, name, info }) =>
          <MedicationsModal id={id} info={info} key={id} name={name} />)}
      </div>
    </section>
  );
};

export default Prescription;