import React from 'react';
import PropTypes from 'prop-types';
import { STAGES } from '../constants';

const Filters = ({ stage, setStage }) =>
  <section className='more-filters'>
    <ul className='tabs'>
      {Object.keys(STAGES).map(s =>
        <li key={s}>
          <span
            className={s === stage ? 'active' : ''}
            onClick={() => {
              setStage(s);
            }}
          >
            {STAGES[s]}
          </span>
        </li>)}
    </ul>
  </section>;

export default Filters;
Filters.propTypes = {
  setStage: PropTypes.func,
  stage: PropTypes.string
};